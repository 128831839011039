











import {Coupon} from "@/request/after-sale/Coupon";

export default {
    data() {
        let self: any =this;
        return {
            modal: Coupon,
            search: [
                {
                    type: 'Input',
                    label: '优惠券名',
                    key: 'name',
                    width: '140px',
                    placeholder: '请填写优惠券名'
                },
                {
                    type: 'DatePicker',
                    pickerType: 'datetimerange',
                    format: 'yyyy-MM-dd HH:mm',
                    label: '日期',
                    width: '220px',
                    placeholder: '请选择日期'
                }
            ],
            columns: [
                {
                    title: '优惠券名',
                    key: 'name',
                    minWidth: 160
                },
                {
                    title: '用户昵称',
                    key: 'nickName',
                    minWidth: 140,
                    tooltip: true
                },
                {
                    title: '电话',
                    key: 'phone',
                    minWidth: 140
                },
                {
                    title: '状态',
                    key: 'status',
                    minWidth: 120,
                    render: (h: any, p: any) => h('p', p.row.status===0?'有效': p.row.status===1? '过期': '已使用')
                },
                {
                    title: '消费门店',
                    key: 'storeName',
                    minWidth: 120
                },
                {
                    title: '门店消费说明',
                    key: 'useDesc',
                    minWidth: 140,
                    tooltip: true
                },
                {
                    title: '门店消费金额',
                    key: 'useFee',
                    minWidth: 140,
                    render: (h: any, p: any) => h('p', self.fen2yuan(p.row.useFee))
                },
                {
                    title: '消费渠道',
                    key: 'useChannel',
                    minWidth: 120,
                    render: (h: any, p: any) => h('p', p.row.useChannel === 0? '线上订单': p.row.useChannel === 1? '线下单独使用': '-')
                },
                {
                    title: '消费时间',
                    key: 'useTime',
                    minWidth: 180
                },
                {
                    title: '操作人',
                    key: 'employeeName',
                    minWidth: 120
                },
                {
                    title: '抵扣金额',
                    key: 'couponFee',
                    minWidth: 120,
                    render: (h: any, p: any) => h('p', self.fen2yuan(p.row.couponFee))
                },
                {
                    title: '领取时间',
                    key: 'createTime',
                    minWidth: 180
                },
                {
                    title: '满减起始金额',
                    key: 'fullFee',
                    minWidth: 140,
                    render: (h: any, p: any) => h('p', self.fen2yuan(p.row.fullFee))
                }
            ]
        }
    },
    created() {
    },
    methods: {
    }
}
